import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Head from "next/head";
import { ReactNode } from "react";

import theme from "../../styles/theme";

type ProviderProps = {
	children?: ReactNode;
};
const Provider = ({ children }: ProviderProps) => {
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
			</Head>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</>
	);
};

export default Provider;
