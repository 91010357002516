import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef } from "react";

import { dynamicActivate, initTranslation } from "../../utils";

initTranslation(i18n);

type ProviderProps = {
	children?: ReactNode;
};

const Provider = ({ children }: ProviderProps) => {
	const router = useRouter();
	const locale = router.locale || router.defaultLocale!;
	const firstRender = useRef(true);

	if (firstRender.current) {
		dynamicActivate(i18n, locale, process.env.NODE_ENV === "production");
		// i18n.activate(locale)
		firstRender.current = false;
	}

	useEffect(() => {
		// i18n.activate(locale)
		dynamicActivate(i18n, locale, process.env.NODE_ENV === "production");
	}, [locale]);

	return (
		<>
			<Head>
				{router.locales!.concat("x-default").map((locale: string) => {
					const localePath =
						locale === "x-default" ? router.asPath : `/${locale}${router.asPath}`;
					const href = `${process.env.NEXT_PUBLIC_APP_URL}${localePath}`;
					return locale === "pseudo" ? null : (
						<link key={locale} rel="alternate" hrefLang={locale} href={href} />
					);
				})}
			</Head>
			<I18nProvider i18n={i18n}>{children}</I18nProvider>
		</>
	);
};

export default Provider;
