import type { I18n } from "@lingui/core";
import { en, my, th, zh } from "make-plural/plurals";

// anounce which locales we are going to use and connect them to approprite plural rules
export function initTranslation(i18n: I18n): void {
	i18n.loadLocaleData({
		en: { plurals: en },
		my: { plurals: my },
		th: { plurals: th },
		zh: { plurals: zh },
		pseudo: { plurals: en },
	});
	i18n.activate("en");
}

export async function loadTranslation(locale: string = "en", isProduction = true) {
	let data;
	if (isProduction) {
		data = await import(`../locales/${locale}/messages`);
	} else {
		data = await import(`@lingui/loader!../locales/${locale}/messages.po`);
	}
	return data.messages;
}

export async function dynamicActivate(i18n: I18n, locale: string, isProduction = true) {
	const messages = await loadTranslation(locale, isProduction);
	i18n.load(locale, messages);
	i18n.activate(locale);
}
