import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface Palette {
		neutral: Palette["primary"];
	}

	// allow configuration using `createTheme`
	interface PaletteOptions {
		neutral?: PaletteOptions["primary"];
	}
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		neutral: true;
	}
}

// Create a theme instance.
const theme = createTheme({
	palette: {
		primary: {
			main: "#3A8DDE",
		},
		secondary: {
			main: "#80BC00",
		},
		error: {
			main: red.A400,
		},
		neutral: {
			main: "rgba(0, 0, 0, 0.87)",
			contrastText: "#fff",
		},
		warning: {
			main: "#FFA400",
		},
	},
	breakpoints: {
		values: {
			xs: 320,
			sm: 576,
			md: 768,
			lg: 1024,
			xl: 1280,
		},
	},
	typography: {
		fontFamily: "Open Sans, sans-serif",
	},
	components: {
		MuiAlert: {
			styleOverrides: {
				standardInfo: {
					backgroundColor: "#E8F1FB",
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				toolbar: { paddingLeft: 0 },
				spacer: { display: "none" },
				displayedRows: { flex: 1, textAlign: "right" },
			},
		},
	},
});

export default theme;
