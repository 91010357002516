import "styles/globals.css";

import { ApolloProvider } from "@apollo/client";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SaleorProvider } from "@saleor/sdk";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { ReactElement, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ChannelProvider } from "@/components/shared/contexts/ChannelContext";
import ErrorComponent from "@/components/shared/ErrorComponent";
import apolloClient, { saleorClient, saleorClientWithoutAutoLogin } from "@/lib/graphql";

import I18nProvider from "../components/providers/I18nProvider";
import SnackbarProvider from "../components/providers/SnackbarProvider";
import ThemeProvider from "../components/providers/ThemeProvider";
import createEmotionCache from "../utils/createEmotionCache";

export type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	emotionCache?: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const MyApp = ({
	Component,
	pageProps,
	emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
	const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
	// const client = Component.name === "LoginPage" ? saleorClientWithoutAutoLogin : saleorClient;
	return (
		<CacheProvider value={emotionCache}>
			<ApolloProvider client={apolloClient}>
				<SaleorProvider client={saleorClient}>
					<I18nProvider>
						<ThemeProvider>
							<ChannelProvider>
								<SnackbarProvider>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<ErrorBoundary FallbackComponent={ErrorComponent}>
											{getLayout(<Component {...pageProps} />)}
										</ErrorBoundary>
									</LocalizationProvider>
								</SnackbarProvider>
							</ChannelProvider>
						</ThemeProvider>
					</I18nProvider>
				</SaleorProvider>
			</ApolloProvider>
		</CacheProvider>
	);
};

export default MyApp;
