/* eslint-disable @typescript-eslint/no-empty-function */
import { useAuthState } from "@saleor/sdk";
import { useRouter } from "next/router";
import React from "react";
import { useLocalStorage } from "react-use";

import { getLocaleFromChannel } from "@/components/utils/channel";
import { useMeQuery } from "@/saleor/api";

export interface ChannelActions {
	setChannel: (channel: string) => void;
	resetChannel: () => void;
	channel: string | undefined;
}

const ChannelContext = React.createContext<ChannelActions>({
	setChannel: () => {},
	resetChannel: () => {},
	channel: "default-channel",
});

export const ChannelProvider: React.FC = ({ children }) => {
	const { authenticated } = useAuthState();
	const [selectedChannel, setSelectedChannel] = useLocalStorage(
		"channel",
		process.env.NEXT_PUBLIC_DEFAULT_CHANNEL
	);

	const router = useRouter();
	const { pathname, asPath, query, defaultLocale } = router;

	const { data: userData } = useMeQuery({
		skip: !authenticated,
	});

	React.useEffect(() => {
		const channelSlug = userData?.me?.channel?.slug;
		const locale = getLocaleFromChannel(channelSlug);
		setSelectedChannel(channelSlug);
		router.push({ pathname, query }, asPath, { locale: locale });
	}, [userData]);

	const resetChannel = () => {
		setSelectedChannel(process.env.NEXT_PUBLIC_DEFAULT_CHANNEL);
		router.push({ pathname, query }, asPath, { locale: defaultLocale });
	};

	const channel = userData?.me?.channel?.slug || selectedChannel;
	return (
		<ChannelContext.Provider
			value={{
				channel,
				setChannel: setSelectedChannel,
				resetChannel: resetChannel,
			}}
		>
			{children}
		</ChannelContext.Provider>
	);
};

function useChannelContext(): ChannelActions {
	const { ...data } = React.useContext(ChannelContext);

	return data;
}

export default useChannelContext;
