import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useReducer } from "react";

import SnackbarContext, { SnackbarState } from "../shared/contexts/SnackBarContext";

export interface SnackbarWrapperProps {
	children?: any;
}

interface SnackbarAction {
	payload?: SnackbarState;
	type: "show" | "hide";
}

const initialState: SnackbarState = {
	open: false,
	vertical: "bottom",
	horizontal: "left",
	message: "",
	autoHideDuration: 5000,
	severity: "info",
	alertVariant: "filled",
};

function reducer(state: SnackbarState, action: SnackbarAction): SnackbarState {
	switch (action.type) {
		case "show":
			return {
				...state,
				...action.payload,
				open: true,
			};
		case "hide":
			return {
				...state,
				open: false,
			};
		default:
			throw new Error();
	}
}

const SnackbarWrapper = ({ children }: SnackbarWrapperProps) => {
	const [snackbar, dispatch] = useReducer(reducer, initialState);

	const showSnackbar = useCallback((newState?: SnackbarState) => {
		dispatch({ type: "show", payload: newState });
	}, []);

	const hideSnackbar = useCallback(() => {
		dispatch({ type: "hide" });
	}, []);

	const {
		open,
		vertical = "bottom",
		horizontal = "right",
		message,
		autoHideDuration = 5000,
		severity = "info",
		alertVariant = "filled",
	} = snackbar;

	const snackbarActions = React.useMemo(
		() => ({
			showSnackbar,
			hideSnackbar,
		}),
		[hideSnackbar, showSnackbar]
	);

	return (
		<>
			<SnackbarContext.Provider value={snackbarActions}>{children}</SnackbarContext.Provider>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={hideSnackbar}
				key={vertical + horizontal}
				autoHideDuration={autoHideDuration}
			>
				<Alert variant={alertVariant} severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default SnackbarWrapper;
