/* eslint-disable @typescript-eslint/no-empty-function */
import { AlertColor } from "@mui/material/Alert";
import React from "react";

export interface SnackbarState {
	open?: boolean;
	message: string;
	autoHideDuration?: number;
	vertical?: "top" | "bottom";
	horizontal?: "left" | "center" | "right";
	severity?: AlertColor;
	alertVariant?: "standard" | "filled" | "outlined";
}

export interface SnackbarActions {
	showSnackbar: (state?: SnackbarState) => void;
	hideSnackbar: () => void;
}

const SnackbarContext = React.createContext<SnackbarActions>({
	showSnackbar: () => {},
	hideSnackbar: () => {},
});

export default SnackbarContext;
