import { t, Trans } from "@lingui/macro";
import { Box, Button, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { FallbackProps } from "react-error-boundary";

const ErrorComponent: React.VFC<FallbackProps> = ({ error, resetErrorBoundary }) => {
	const router = useRouter();

	const goHome = () => {
		resetErrorBoundary();
		router.push("/");
	};

	const getHelp = () => {
		resetErrorBoundary();
		router.push("/");
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="100%"
			height="100vh"
			flexDirection="column"
		>
			<Box maxWidth={500}>
				<div className="flex-container center-centered pt-4 px-2">
					<Typography
						variant="h4"
						className="bold-text text-center "
						color="textPrimary"
						gutterBottom
					>
						<Trans>SORRY :(</Trans>
					</Typography>
					<Image
						src="https://goalsmapper-web.s3-ap-southeast-1.amazonaws.com/public/platform/empty_state/error_page.png"
						alt="Error"
						style={{ maxWidth: 500, width: "100%" }}
						layout="fill"
					/>
					<div className="pad-vertical" />
					<div className="flex-container horizontal mobile-vertical px-2">
						<div className="flex-container flex-2x pt-4">
							<Typography
								variant="subtitle1"
								className=" text-center "
								color="textPrimary"
								gutterBottom
							>
								<Trans>
									Looks like something went wrong. <br />
									<br />
									Sometimes, clearing the browser cache or refreshing the page may
									help but if the problem persist, please contact our support
									team.
								</Trans>
							</Typography>
						</div>
						<div className="flex-container flex-full" />
					</div>
				</div>
				<Stack direction="row" spacing={2} pt={4} justifyContent="center">
					<Button variant="contained" color="primary" onClick={getHelp}>
						<Trans>Contact our support team</Trans>
					</Button>
					<Button variant="outlined" onClick={goHome}>
						<Trans>Home</Trans>
					</Button>
				</Stack>
			</Box>
		</Box>
	);
};

export default ErrorComponent;
