import { ImageLoaderProps } from "next/image";

import { TableCellHeaderArrowDirection } from "@/components/shared/table/TableCellHeader";

export const formatAsMoney = (amount = 0, currency = "SGD") =>
	new Intl.NumberFormat("en-SG", {
		minimumFractionDigits: 2,
		style: "currency",
		currency,
	}).format(amount);

// Returns true for non nullable values
export function notNullable<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

export const getYouTubeIDFromURL = (url: string) => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = url.match(regExp);
	return match && match[7].length == 11 ? match[7] : undefined;
};

export const cloudfrontImageLoader = (resolverProps: ImageLoaderProps): string => {
	return resolverProps.src;
};

export function getArrowDirection(asc: boolean): TableCellHeaderArrowDirection {
	return asc ? "asc" : "desc";
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function findValueInEnum<TEnum extends {}>(
	needle: string,
	haystack: TEnum
): TEnum[keyof TEnum] {
	const match = Object.entries(haystack).find(([_, value]) => value === needle);

	if (!match) {
		throw new Error(`Value ${needle} not found in enum`);
	}

	return needle as unknown as TEnum[keyof TEnum];
}

export function parseBoolean(a: string, defaultValue: boolean): boolean {
	if (a === undefined) {
		return defaultValue;
	}
	return a === "true";
}
