import { relayStylePagination } from "@apollo/client/utilities";

import { PriceRangeFragment, TypedTypePolicies } from "@/saleor/api";

import { formatAsMoney } from "./util";

export const typePolicies: TypedTypePolicies = {
	Money: {
		fields: {
			localizedAmount: {
				read(_, { readField }) {
					return formatAsMoney(readField("amount"), readField("currency"));
				},
			},
		},
	},
	ProductPricingInfo: {
		fields: {
			salePercent: {
				read(_, { readField }) {
					const onSale = readField<boolean>("onSale");
					if (!onSale) {
						return 0;
					}
					const start = readField<PriceRangeFragment>("priceRange");
					const end = readField<PriceRangeFragment>("priceRangeUndiscounted");
					// return get(start, "gross.amount", 0) / get(end, "gross.amount", 1);
					const startAmount = start?.start?.gross.amount || 0;
					const endAmount = end?.start?.gross.amount || 1;
					return startAmount / endAmount;
				},
			},
		},
	},
	User: {
		fields: {
			orders: relayStylePagination(),
		},
	},
	Query: {
		fields: {
			products: relayStylePagination(["filter"]),
			advisorOrders: relayStylePagination(),
			goalsmapperClients: relayStylePagination(["filter"]),
			coinVouchers: relayStylePagination([["filter"]]),
			customers: relayStylePagination([["filter"]]),
			orders: relayStylePagination(),
			userOrders: relayStylePagination(),
			merchants: relayStylePagination(),
			userDraftCheckouts: relayStylePagination(),
			userMergedCheckouts: relayStylePagination(),
		},
	},
};
