import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { createFetch, createSaleorClient } from "@saleor/sdk";

import { API_URI, DEFAULT_CHANNEL } from "./const";
import { typePolicies } from "./typePolicies";

const httpLink = new BatchHttpLink({
	uri: API_URI,
	batchMax: 3, // No more than 5 operations per batch
	batchInterval: 20, // Wait no more than 20ms after first batched operation,
	fetch: createFetch(),
});

// const httpLink = createHttpLink({
// 	uri: API_URI,
// 	fetch: createFetch(),
// });

const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ extensions, message, locations, path }) => {
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			);
			if ((extensions?.exception as any)?.code === "InvalidSignatureError") {
				// Redirect to the home page
				window.location.href = "/";
			}
		});
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
		// console.log(JSON.stringify(operation.query, null, 2));
		console.log(networkError);
	}
});

const link = from([errorLink, httpLink]);

const apolloClient = new ApolloClient({
	link,
	cache: new InMemoryCache({ typePolicies }),
	ssrMode: typeof window === "undefined",
	defaultOptions: {
		query: {
			fetchPolicy: "network-only",
		},
	},
	connectToDevTools: true,
});

export const saleorClient = createSaleorClient({
	apiUrl: API_URI,
	channel: DEFAULT_CHANNEL,
	opts: {
		autologin: true,
		fetchOpts: { autoTokenRefresh: true },
	},
});

export const saleorClientWithoutAutoLogin = createSaleorClient({
	apiUrl: API_URI,
	channel: DEFAULT_CHANNEL,
	opts: {
		autologin: false,
		fetchOpts: { autoTokenRefresh: true },
	},
});

export default apolloClient;
