export const getLocaleFromChannel = (channel?: string) => {
	switch (channel) {
		case "malaysia":
			return "my";
		default:
			return "en";
	}
};

export const getChannelFromLocale = (locale?: string) => {
	switch (locale) {
		case "my":
			return "malaysia";
		default:
			return "default-channel";
	}
};

export const getCurrencyPrefixForLocale = (locale?: string) => {
	switch (locale) {
		case "my":
			return "RM";
		default:
			return "S$";
	}
};
